import React from 'react';
import {
    withSitecoreContext,
    Placeholder,
    getChildPlaceholder,
} from '@sitecore-jss/sitecore-jss-react';
import get from 'lodash/get';

// This component can be used to render a static layout placed in the sitecore context
// https://jss.sitecore.com/docs/techniques/extending-layout-service/layoutservice-static-context-rendering
function ConditionalStaticPlaceholder({
    sitecoreContext,
    contextKey,
    name,
    ...otherProps
}) {
    let placeholderData = get(sitecoreContext, contextKey, {});

    // if we have route data that places components in the target placeholder,
    // we're editing the definition item of the static item, so we should
    // render as a normal placeholder
    if (sitecoreContext.route) {
        const childPlaceholder = getChildPlaceholder(
            sitecoreContext.route,
            name
        );

        if (
            childPlaceholder &&
            childPlaceholder.some((rendering) => rendering.componentName)
        ) {
            placeholderData = sitecoreContext.route;
        }
    }

    return (
        <Placeholder name={name} rendering={placeholderData} {...otherProps} />
    );
}

export default withSitecoreContext()(ConditionalStaticPlaceholder);
