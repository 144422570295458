import { useEffect } from 'react';
import clearAnalyticsEvent from '../utilities/clearAnalyticsEvent';
import { ANALYTICS_EVENT } from './constants';

const useInternalPageViewAnalytics = (
    searchPageName,
    searchPageCategory,
    searchPageHierarchy,
    isResultPage
) => {
    useEffect(() => {
        if (!isResultPage) {
            return;
        }

        const gtmPageViewObject = clearAnalyticsEvent(
            ANALYTICS_EVENT.PAGE_VIEW
        );

        if (gtmPageViewObject) {
            gtmPageViewObject.pageInfo.pageName = searchPageName;
            gtmPageViewObject.pageInfo.pageCategory = searchPageCategory;
            gtmPageViewObject.pageInfo.pageHierarchy = searchPageHierarchy;
            window.dataLayer.push(gtmPageViewObject);
        }
    }, [isResultPage]);
};

export default useInternalPageViewAnalytics;
