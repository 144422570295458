import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ANALYTICS_EVENT } from './constants';

function getAppropriateSiteVersion(siteName, t) {
    switch (siteName) {
        case 'MHealthFairviewOrg':
            return t('siteinfo.siteVersionMHF', {
                defaultValue: '8.1:131123',
            });

        case 'PediatricsMHealthFairviewOrg':
            return t('siteinfo.siteVersionPEDS', {
                defaultValue: '8.1:131123',
            });

        case 'FairviewOrg':
            return t('siteinfo.siteVersionFV', {
                defaultValue: '8.1:131123',
            });

        case 'GrandItascaOrg':
            return t('siteinfo.siteVersionGICH', {
                defaultValue: '8.1:131123',
            });
        default:
            return t('siteinfo.siteVersion', {
                defaultValue: '8.1:131123',
            });
    }
}

const usePageLayoutAnalytics = (route, context) => {
    const { t } = useTranslation();

    useEffect(() => {
        const pageViewObject = {
            event: ANALYTICS_EVENT.PAGE_VIEW,
            siteInfo: {
                environment: context.siteEnvironmentInfo.siteEnvironmentName,
                siteVersion: getAppropriateSiteVersion(context.site?.name, t),
            },
            pageInfo: {
                pageName: route.fields.pageName.value.toLowerCase(),
                pageCategory: route.fields.pageCategory.value.toLowerCase(),
                pageHierarchy: context.gaDataLayer.pageHierarchy,
            },
            userInfo: {
                loginStatus: t('googleAnalytics.userInfo.nonAuthenticated', {
                    defaultValue: 'non-authenticated',
                }),
                userType: t('googleAnalytics.userInfo.guest', {
                    defaultValue: 'guest',
                }),
            },
        };

        if (route.fields.resourceDetails.value) {
            pageViewObject.resourceInfo = {
                resourceDetails: `${route.fields.resourceDetails.value.trim()}:${route.fields.pageTitle.value.trim()}`,
            };
        }

        const dataLayerObjCopy = new Array(...window.dataLayer);
        window.dataLayer.splice(0, window.dataLayer.length);
        dataLayerObjCopy.forEach((obj) => {
            if (
                (obj?.event && obj.event === 'gtm.js') ||
                (obj?.length > 0 && (obj[0] === 'js' || obj[0] === 'config'))
            ) {
                window.dataLayer.push(obj);
            }
        });

        window.dataLayer.push(pageViewObject);
    }, [route.itemId]);
};

export default usePageLayoutAnalytics;
