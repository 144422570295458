const ANALYTICS_CATEGORY_NAME = {
    PROVIDER_PAGE: 'provider',
    PROVIDER_RESULT_PAGE: 'provider-result-page',
    LOCATION_PAGE: 'location',
    FAQ_PAGE: 'faqs',
    FAQ_RESULT_PAGE: 'faqs-result-page',
    LOCATION_RESULT_PAGE: 'location-result-page',
    CONDITION_PAGE: 'condition',
    SPECIALTY_PAGE: 'speciality',
    SERVICE_PAGE: 'service',
    TREATMENT_PAGE: 'treatment',
    CONDITION_RESULT_PAGE: 'specialty-result-page',
    BLOG_PAGE: 'blog',
    BLOG_RESULT_PAGE: 'content-page',
    GLOBAL_SEARCH_ALL: 'gs:all',
    GLOBAL_SEARCH_PROVIDERS: 'gs:providers',
    GLOBAL_SEARCH_LOCATIONS: 'gs:locations',
    GLOBAL_SEARCH_SERVICES: 'gs:services',
    GLOBAL_SEARCH_BLOGS: 'gs:blogs',
    GLOBAL_SEARCH_OTHERS: 'gs:others',
};

const ANALYTICS_SEARCH_TYPE = {
    TYPED: 'typed',
    POPULAR: 'popular',
    TYPED_RECOMMENDED: 'typed-and-recommended',
    ALL_CTSS: 'all-ctss',
    ALL_PROVIDER: 'all-provider',
    ALL_LOCATION: 'all-location',
    ALL_BLOG: 'all-blog',
};

const ANALYTICS_SORT_ORDER = {
    MOST_RELEVANT: 'most-relevant',
};

const ANALYTICS_NULL_VALUES = {
    NA_VALUE: 'na',
    NO_SEARCH_TERM: 'no-search-term-available',
};

const ANALYTICS_RESOURCE_NAME = {
    PROVIDER: 'prov',
    LOCATIONS: 'loc',
    CONDITION: 'cond',
    TREATMENT: 'trt',
    SPECIALTY: 'spc',
    SERVICES: 'serv',
    BLOG: 'blog',
    OTHERS: 'other',
    FAQ: 'faq',
};

const ANALYTICS_EVENT = {
    CLICK: 'click',
    SEARCH_FILTER: 'search-filter',
    INTERNAL_SEARCH: 'internal-search',
    PAGE_VIEW: 'page-view',
    PAGE_SECTION_VIEW: 'page-section-view',
    SEARCH_RESULT_CLICK: 'search-result-click',
    SCROLL: 'scroll',
    GLOBAL_SEARCH: 'global-search',
    GLOBAL_SEARCH_RESULT_CLICK: 'global-search-result-click',
    ERROR_HANDLING: 'exception',
};

const ANALYTICS_VIEW = {
    LIST: 'list',
    MAP: 'map',
    GLOBAL_DROPDOWN: 'gs-dropdown',
};

const ANALYTICS_PAGE_NAME = {
    PAGE_NAME_LIST: 'result-list',
    PAGE_NAME_MAP: 'result-map',
};

const ANALYTICS_SITE_NAME = {
    M_HEALTH_FAIRVIEW: process.env.REACT_APP_MHF_SITE_NAME,
    FAIRVIEW: process.env.REACT_APP_FV_SITE_NAME,
};

const ANALYTICS_PAGE_HIERARCHY = {
    [ANALYTICS_SITE_NAME.M_HEALTH_FAIRVIEW]: {
        HIERARCHY_PROVIDER: 'mhf/find-a-provider',
        HIERARCHY_LOCATION: 'mhf/find-a-location',
        HIERARCHY_CONDITION: 'mhf/find-a-specialty',
        HIERARCHY_BLOG: 'mhf/find-a-blog-news',
        HIERARCHY_FAQ: 'mhf/find-a-faq',
    },
    [ANALYTICS_SITE_NAME.FAIRVIEW]: {
        HIERARCHY_PROVIDER: 'fv/find-a-provider',
    },
};

export {
    ANALYTICS_CATEGORY_NAME,
    ANALYTICS_SEARCH_TYPE,
    ANALYTICS_SORT_ORDER,
    ANALYTICS_NULL_VALUES,
    ANALYTICS_EVENT,
    ANALYTICS_VIEW,
    ANALYTICS_PAGE_NAME,
    ANALYTICS_PAGE_HIERARCHY,
    ANALYTICS_SITE_NAME,
    ANALYTICS_RESOURCE_NAME,
};
