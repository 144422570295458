import { useEffect } from 'react';
import { isClient } from '@fhs/shared/src/utilities/ssr';

export function useWindowEvent(event, callback) {
    useEffect(() => {
        if (!isClient()) {
            return undefined;
        }

        window.addEventListener(event, callback);
        return () => window.removeEventListener(event, callback);
    }, [event, callback]);
}

export function useWindowResizeEvent(callback) {
    return useWindowEvent('resize', callback);
}
