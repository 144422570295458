import { ANALYTICS_EVENT, ANALYTICS_NULL_VALUES } from './constants';
import clearAnalyticsEvent from '../utilities/clearAnalyticsEvent';

const useGlobalSearchClickAnalytics = (
    searchInfo,
    resourceDetails,
    searchPosition,
    customAnalyticObject,
    customAnalyticFields
) => {
    clearAnalyticsEvent(ANALYTICS_EVENT.GLOBAL_SEARCH_RESULT_CLICK);

    const gtmGlobalSearchResultClick = {
        event: ANALYTICS_EVENT.GLOBAL_SEARCH_RESULT_CLICK,
        searchInfo: {
            searchTerm:
                searchInfo.searchTerm || ANALYTICS_NULL_VALUES.NO_SEARCH_TERM,
            searchCategory: searchInfo.searchCategory,
            searchResultsCount: searchInfo.searchResultsCount,
            searchTermCategory: searchInfo.searchTermCategory,
            ...customAnalyticFields,
        },
        resourceInfo: {
            resourceDetails,
        },
        clickedResult: {
            searchPosition,
        },
        ...customAnalyticObject,
    };

    window.dataLayer.push(gtmGlobalSearchResultClick);
};

useGlobalSearchClickAnalytics.defaultProps = {
    customAnalyticObject: {},
    customAnalyticFields: {},
};

export default useGlobalSearchClickAnalytics;
