import { useEffect, useState } from 'react';
import useInternalPageViewAnalytics from './useInternalPageViewAnalytics';
import { ANALYTICS_NULL_VALUES, ANALYTICS_EVENT } from './constants';
import clearAnalyticsEvent from '../utilities/clearAnalyticsEvent';

const useInternalSearchAnalytics = (
    filtersModel,
    resultsModel,
    searchParams,
    searchParamKeys,
    searchInternalCategory,
    termCategory,
    analyticView,
    searchPageName,
    searchPageCategory,
    searchPageHierarchy,
    sortOrder,
    customAnalyticObject,
    customAnalyticFields
) => {
    const [isResultPage, setIsResultPage] = useState(false);

    useInternalPageViewAnalytics(
        searchPageName,
        searchPageCategory,
        searchPageHierarchy,
        isResultPage
    );

    useEffect(() => {
        clearAnalyticsEvent(ANALYTICS_EVENT.INTERNAL_SEARCH);
        if (
            resultsModel.query?.isLoading ||
            resultsModel.query?.isError ||
            resultsModel.query?.isFetching ||
            resultsModel.query?.isStale ||
            resultsModel.numResultsTotal === -1
        ) {
            return;
        }
        setIsResultPage(true);

        const gtmInternalSearchResult = {
            event: ANALYTICS_EVENT.INTERNAL_SEARCH,
            searchInfo: {
                searchTerm:
                    searchParams[searchParamKeys.SEARCH_QUERY] ||
                    ANALYTICS_NULL_VALUES.NA_VALUE,
                location:
                    searchParams[searchParamKeys.ADDRESS_QUERY] ||
                    ANALYTICS_NULL_VALUES.NA_VALUE,
                searchCategory: searchInternalCategory,
                searchResultsCount: resultsModel.numResultsTotal,
                searchPageDetails: {
                    currentPage: resultsModel.currentPageNumber,
                    totalPages: resultsModel.totalPages,
                },
                searchSort: sortOrder,
                searchView: analyticView,
                searchTermCategory: termCategory,
                ...customAnalyticFields,
            },
            filterInfo: {
                filterApplied: filtersModel.numFiltersApplied > 0,
            },
            ...customAnalyticObject,
        };

        window.dataLayer.push(gtmInternalSearchResult);
    }, [
        resultsModel.query?.isFetching,
        searchPageName,
        resultsModel.numResultsTotal,
        sortOrder,
    ]);
};

useInternalSearchAnalytics.defaultProps = {
    customAnalyticObject: {},
    customAnalyticFields: {},
};

export default useInternalSearchAnalytics;
