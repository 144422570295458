import { useState, useEffect } from 'react';
import { ANALYTICS_EVENT } from './constants';

const useScrollTracking = (route) => {
    const [scrollThreshold, setScrollThreshold] = useState({
        25: false,
        50: false,
        75: false,
        90: false,
    });

    useEffect(() => {
        setScrollThreshold({
            25: false,
            50: false,
            75: false,
            90: false,
        });
    }, [route.itemId]);

    useEffect(() => {
        const gtmScrollObject = {
            event: ANALYTICS_EVENT.SCROLL,
            scrollInfo: {
                scrollDirection: 'vertical-scroll',
                scrollMilestone: '',
            },
        };

        if (scrollThreshold[90]) {
            gtmScrollObject.scrollInfo.scrollMilestone = '90%';
        } else if (scrollThreshold[75]) {
            gtmScrollObject.scrollInfo.scrollMilestone = '75%';
        } else if (scrollThreshold[50]) {
            gtmScrollObject.scrollInfo.scrollMilestone = '50%';
        } else if (scrollThreshold[25]) {
            gtmScrollObject.scrollInfo.scrollMilestone = '25%';
        }

        if (!gtmScrollObject.scrollInfo.scrollMilestone) {
            return;
        }

        window.dataLayer.push(gtmScrollObject);
    }, [scrollThreshold]);

    return { scrollThreshold, setScrollThreshold };
};

export default useScrollTracking;
