import { useEffect } from 'react';
import { ANALYTICS_EVENT, ANALYTICS_NULL_VALUES } from './constants';
import clearAnalyticsEvent from '../utilities/clearAnalyticsEvent';

const useGlobalSearchAnalytics = (
    searchTerm,
    searchCategory,
    searchResultsCount,
    currentPage,
    totalPages,
    searchTermCategory,
    query,
    customAnalyticObject,
    customAnalyticFields
) => {
    useEffect(() => {
        clearAnalyticsEvent(ANALYTICS_EVENT.GLOBAL_SEARCH);
        if (query.isFetching) {
            return;
        }

        const gtmGlobalSearchResult = {
            event: ANALYTICS_EVENT.GLOBAL_SEARCH,
            searchInfo: {
                searchTerm: searchTerm || ANALYTICS_NULL_VALUES.NO_SEARCH_TERM,
                searchCategory,
                searchResultsCount,
                searchPageDetails: {
                    currentPage,
                    totalPages,
                },
                searchTermCategory,
                ...customAnalyticFields,
            },
            ...customAnalyticObject,
        };

        window.dataLayer.push(gtmGlobalSearchResult);
    }, [query.isFetching]);
};

useGlobalSearchAnalytics.defaultProps = {
    customAnalyticObject: {},
    customAnalyticFields: {},
};

export default useGlobalSearchAnalytics;
