import React, { useEffect } from 'react';
import {
    Placeholder,
    VisitorIdentification,
} from '@sitecore-jss/sitecore-jss-react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Helmet from 'react-helmet';
import {
    usePageLayoutAnalytics,
    useScrollTracking,
    ANALYTICS_EVENT,
    ANALYTICS_NULL_VALUES,
} from '@fhs/shared/src/analytics';
import { useWindowEvent } from './hooks/events';
import usePureDebouncedCallback from './hooks/usePureDebouncedCallback';
import ConditionalStaticPlaceholder from './ConditionalStaticPlaceholder';

// Analytics error handling starts
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        let errorDescription = '';
        if (error.response) {
            // Request made and server responded
            errorDescription = `${error.response.status}:${error.response.statusText}`;
        } else if (error.request) {
            // Request made and server did not respond
            errorDescription = `${error.request.status}:${error.request.statusText}`;
        } else {
            errorDescription = error.message;
        }

        if (window) {
            const errorEvent = window.dataLayer.filter(
                (item) => item.event === ANALYTICS_EVENT.ERROR_HANDLING
            );

            if (errorEvent && !errorEvent.length) {
                window.dataLayer.push({
                    event: ANALYTICS_EVENT.ERROR_HANDLING,
                    errorInfo: {
                        description: errorDescription,
                        fatal: true,
                    },
                });
            }
        }

        return Promise.reject(error);
    }
);
// Analytics error handling end

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/
const Layout = ({ route, context }) => {
    useEffect(() => {
        if (
            window &&
            window.google_tag_manager &&
            process.env.REACT_APP_FV_GTM_ID
        ) {
            window.google_tag_manager[
                process.env.REACT_APP_FV_GTM_ID
            ].dataLayer.reset();
        }
    }, [route.itemId]);

    let redirectDictionary;
    const { t } = useTranslation();
    const canonicalUrl = context?.canonicalDataLayer?.canonicalUrl;

    try {
        redirectDictionary = JSON.parse(
            t('redirects.fv', { defaultValue: '[]' })
        );

        redirectDictionary.forEach((val) => {
            if (window.location.pathname === val.pathName) {
                window.location.replace(val.extRedirect);
            }
        });
    } catch (err) {
        redirectDictionary = [];
        console.error("Fix the 'redirects.fv' dictionary item");
    }

    usePageLayoutAnalytics(route, context);
    const { scrollThreshold, setScrollThreshold } = useScrollTracking(route);

    const gtmClickTracking = (event) => {
        if (
            !event.target.classList ||
            event.target.classList.contains('Link')
        ) {
            return;
        }

        const isAnchorTag = event.target.tagName.toLowerCase() === 'a';
        const isButton =
            event.target.classList.contains('btn') ||
            event.target.tagName.toLowerCase() === 'button' ||
            event.target.attributes.role?.value === 'button';

        if (!isAnchorTag && !isButton) {
            return;
        }

        const gtmClickObject = {
            event: ANALYTICS_EVENT.CLICK,
            clickInfo: {
                clickUrl: event.target.href || ANALYTICS_NULL_VALUES.NA_VALUE,
                clickElement: event.target.tagName.toLowerCase(),
                clickText:
                    event.target.text ||
                    event.target.textContent ||
                    event.target.attributes['aria-label']?.value ||
                    ANALYTICS_NULL_VALUES.NA_VALUE,
                clickElementPosition: '',
                clickClass: event.target.classList.value,
            },
        };

        window.dataLayer.push(gtmClickObject);
    };

    // To-Do:: move implementation to hook once usePureDebouncedCallback & useWindowEvent move to shared hooks
    const gtmScrollEventCallback = usePureDebouncedCallback(() => {
        if (!scrollThreshold || scrollThreshold[90]) {
            return;
        }

        const scrollTop = window.scrollY;
        const bodyHeight = document.body.scrollHeight;
        const windowHeight = window.innerHeight;
        const scrollPercent = scrollTop / (bodyHeight - windowHeight);
        const scrollPercentRounded = Math.round(scrollPercent * 100);

        if (Number(scrollPercentRounded) >= 25 && !scrollThreshold[25]) {
            setScrollThreshold((prevState) =>
                !prevState[25] ? { ...prevState, 25: true } : prevState
            );
        }
        if (Number(scrollPercentRounded) >= 50 && !scrollThreshold[50]) {
            setScrollThreshold((prevState) =>
                !prevState[50] ? { ...prevState, 50: true } : prevState
            );
        }
        if (Number(scrollPercentRounded) >= 75 && !scrollThreshold[75]) {
            setScrollThreshold((prevState) =>
                !prevState[75] ? { ...prevState, 75: true } : prevState
            );
        }
        if (Number(scrollPercentRounded) >= 90 && !scrollThreshold[90]) {
            setScrollThreshold((prevState) =>
                !prevState[90] ? { ...prevState, 90: true } : prevState
            );
        }
    }, 500);

    useWindowEvent('scroll', gtmScrollEventCallback);

    return (
        <>
            {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
            <Helmet>
                {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
                <title>
                    {(route.fields &&
                        route.fields.pageTitle &&
                        route.fields.pageTitle.value) ||
                        'Page'}
                </title>
            </Helmet>

            {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
        */}
            <VisitorIdentification />

            {/* root placeholder for the app, which we add components to using route data */}
            <div role="none" className="site" onClick={gtmClickTracking}>
                <ConditionalStaticPlaceholder
                    name="jss-banner"
                    contextKey="covidBanner.sitecore.route"
                />
                <header className="site__header">
                    {/*
                Rendering for primary nav is in sitecore context and following this technique
                https://jss.sitecore.com/docs/techniques/extending-layout-service/layoutservice-static-context-rendering
                */}
                    <ConditionalStaticPlaceholder
                        name="jss-nav"
                        contextKey="Navigation.sitecore.route"
                    />
                    {/* <GlobalPrimaryNav fields={data.sitecore.context.fvNavigation.sitecore.route.placeholders["jss-nav"][0].fields}/> */}
                </header>
                <div className="site__body">
                    <main id="mainContent">
                        <Placeholder name="jss-main" rendering={route} />
                    </main>
                    {/*
                Rendering for footer is in sitecore context and following this technique
                https://jss.sitecore.com/docs/techniques/extending-layout-service/layoutservice-static-context-rendering
                */}
                    <ConditionalStaticPlaceholder
                        name="jss-footer"
                        contextKey="Navigation.sitecore.route"
                    />
                    {/* <GlobalFooter rendering={footerData.rendering}/> */}
                </div>
            </div>

            {/* Modals will be mounted here via react portal to facilitate easier focus management */}
            <div className="site-modals" />
        </>
    );
};

export default Layout;
