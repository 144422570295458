import { useEffect } from 'react';
import {
    ANALYTICS_SORT_ORDER,
    ANALYTICS_NULL_VALUES,
    ANALYTICS_EVENT,
} from './constants';
import clearAnalyticEvent from '../utilities/clearAnalyticsEvent';

const useSearchFilterAnalytics = (
    filtersModel,
    resultsModel,
    searchParams,
    searchParamKeys,
    searchCategory,
    analyticView,
    customAnalyticObject,
    customAnalyticFields
) => {
    const numResultTotal = resultsModel.numResultsTotal || 0;
    const numAppliedFilters = filtersModel.numFiltersApplied || 0;
    const filtersApplied = filtersModel.filtersApplied || [];
    const isFilterApplied = filtersModel.numFiltersApplied > 0;

    useEffect(() => {
        clearAnalyticEvent(ANALYTICS_EVENT.SEARCH_FILTER);
        if (
            !isFilterApplied ||
            resultsModel.query.isLoading ||
            resultsModel.query.isError ||
            resultsModel.query.isFetching
        ) {
            return;
        }

        const gtmFilterSearchResult = {
            event: ANALYTICS_EVENT.SEARCH_FILTER,
            searchInfo: {
                searchTerm:
                    searchParams[searchParamKeys.SEARCH_QUERY] ||
                    ANALYTICS_NULL_VALUES.NA_VALUE,
                location:
                    searchParams[searchParamKeys.ADDRESS_QUERY] ||
                    ANALYTICS_NULL_VALUES.NA_VALUE,
                searchCategory,
                searchResultsCount: numResultTotal,
                searchSort:
                    searchParams[searchParamKeys.SORT_ORDER] ||
                    ANALYTICS_SORT_ORDER.MOST_RELEVANT,
                searchView: analyticView,
                ...customAnalyticFields,
            },
            filterInfo: {
                filterApplied: isFilterApplied,
            },
            ...customAnalyticObject,
        };
        window.dataLayer.push(gtmFilterSearchResult);
    }, [numResultTotal, numAppliedFilters, ...filtersApplied]);
};

useSearchFilterAnalytics.defaultProps = {
    customAnalyticObject: {},
    customAnalyticFields: {},
};

export default useSearchFilterAnalytics;
