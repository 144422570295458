function clearAnalyticEvent(eventToRemove) {
    const indexEvent = window.dataLayer.findIndex(
        (dataLayer) => dataLayer.event === eventToRemove
    );

    if (indexEvent !== -1) {
        const gtmEventObject = { ...window.dataLayer[indexEvent] };
        window.dataLayer.splice(indexEvent, 1);
        return gtmEventObject;
    }

    return 0;
}

export default clearAnalyticEvent;
